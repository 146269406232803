export var SearchList = [
  { labe: '物料编号', code: 'materialCode', type: 'input' },
  { labe: '物料描述', code: 'materialDesc', type: 'input' },
  { labe: '供应商名称', code: 'supplierName', type: 'input' },
  {
    labe: '物料状态',
    code: 'materialStatus',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '发布前', value: '10' },
      { label: '发布', value: '11' },
      { label: '预停产', value: '12' },
      { label: '停产', value: '13' },
      { label: '禁止采购', value: '14' },
      { label: '禁止移动', value: '15' },
      { label: '停用', value: '16' }
    ]
  }
]
export var SearchData = {
  materialCode: '',
  materialDesc: '',
  supplierName: '',
  materialStatus: ''
}

export var tableField = [
  { label: '物料编号', code: 'materialCode', type: 'input', width: '' },
  { label: '物料描述', code: 'materialDesc', type: 'input', width: '' },
  { label: '供应商名称', code: 'supplierName', type: 'input', width: '' },
  { label: '最小批量', code: 'minBatch', type: 'input', width: '' },
  { label: 'LT交期', code: 'deliveryTime', type: 'input', width: '' },
  { label: '是否贴条码', code: 'isHasBarcode', type: 'input', width: '' },
  { label: 'ECN类别', code: 'ecnType', type: 'input', width: '' },
  { label: 'ECN变更描述', code: 'ecnUpdateDesc', type: 'input', width: '' },
  { label: '物料状态', code: 'materialStatus', type: 'input', width: '' },
  { label: '采购员', code: 'buyerName', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [{
      actionLabel: '编辑',
      id: '10'
    }, {
      actionLabel: '查看',
      id: '11'
    }]
  }
]

export var materialData = {
  materialCode: '',
  materialDesc: '',
  supplierName: '',
  minBatch: '',
  deliveryTime: '',
  isHasBarcode: '',
  ecnType: '',
  ecnUpdateDesc: '',
  materialStatus: ''
}
