<template>
  <div>
    <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
    <global-table
      :tableField="tableField"
      :tableData="tableData"
      :paginationData="paginationData"
      @pageChange="pageChange"
      @tableAction="tableAction"
    ></global-table>
    <dialog-box ref="dialogbox" dialogWidth="50%" :dialogShow="dialogShow" :showData = "showData" componentName="MaterialBody"
            @handleClose='handleClose' :title="title"
            :buttonData="buttonData" @action="action" :isReadonly="isReadonly"></dialog-box>
  </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
import { SearchList, SearchData, tableField } from './js/setting.js'
export default {
  name: 'MaterialManage',
  components: Component.components,
  data: function () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    FlowCtlData.docid = ''
    return {
      childData: { searchList: SearchList, searchData: SearchData },
      tableField: tableField,
      tableData: [],
      paginationData: {
        pageSize: pageSize,
        total: 1
      },
      searchdata: {},
      dialogShow: false,
      title: '查看',
      showData: {},
      selectData: {},
      pageSize: pageSize,
      isReadonly: false,
      buttonData: [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'action', size: 'mini', type: 'primary' }]
    }
  },
  created: function () {
    request('/api/supplier/material/list', 'get', { pageNum: 1, pageSize: this.pageSize }).then((Response) => {
      if (Response.code === '200') {
        this.tableData = Response.data.records
        this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        FlowCtlData.flowid = 'MaterialChange'
        FlowCtlData.initialize()
      }
    })
  },
  methods: {
    tableAction: function (index, data, act) {
      if (act.id === '10') {
        this.title = '编辑'
        this.isReadonly = false
        this.dialogShow = true
        this.selectData = data
        this.showData = { ...data }
        this.buttonData = [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'action', size: 'mini', type: 'primary' }]
      }
      if (act.id === '11') {
        this.title = '查看'
        this.isReadonly = true
        this.dialogShow = true
        this.selectData = data
        this.showData = { ...data }
        this.buttonData = [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'handleClose', size: 'mini', type: 'primary' }]
      }
    },
    action: function () {
      var apprData = this.$refs.dialogbox.$refs.content.showData
      /// purchase
      if (apprData.deliveryTime !== this.selectData.deliveryTime ||
          apprData.isHasBarcode !== this.selectData.isHasBarcode ||
          apprData.minBatch !== this.selectData.minBatch ||
          apprData.ecnType !== this.selectData.ecnType ||
          apprData.ecnUpdateDesc !== this.selectData.ecnUpdateDesc) {
        apprData.purchase = apprData.buyerCode
        FlowCtlData.getNodeAction('TJ', apprData).then(
          (val) => {
            if (val) {
              FlowCtlData.alertDialog(this).then(() => {
                FlowCtlData.setFormData(FlowCtlData, apprData)
                request('/api/supplier/material/updateById/1', 'put', apprData).then((response) => {
                  if (response.code === '200') {
                    FlowCtlData.docid = response.data
                    FlowCtlData.setNodeAction().then(
                      (val) => {
                        if (val) {
                          this.$message({
                            showClose: true,
                            message: '提交成功',
                            type: 'success'
                          })
                          FlowCtlData.docid = ''
                        } else {
                          this.$message({
                            showClose: true,
                            message: '未找到审批人！',
                            type: 'error'
                          })
                        }
                      }
                    ).catch((error) => {
                      console.log(error)
                      this.$message({
                        showClose: true,
                        message: '发布失败',
                        type: 'error'
                      })
                    })
                  }
                })
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消提交'
                })
              })
            }
          }
        )
      } else {
        request('/api/supplier/material/updateById/2', 'put', apprData).then((response) => {
          if (response.code === '200') {
            request('/api/supplier/material/list', 'get', { pageNum: 1, pageSize: this.pageSize }).then((Response) => {
              if (Response.code === '200') {
                this.tableData = Response.data.records
                this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
              }
            })
            this.$message({
              showClose: true,
              message: '提交成功',
              type: 'success'
            })
          }
        })
      }
      this.dialogShow = false
    },
    handleClose: function () {
      this.dialogShow = false
    },
    back: () => {
      window.history.back()
    },
    pageChange: function (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      request('/api/supplier/material/list', 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    },
    search: function (data) {
      data.pageNum = 1
      data.pageSize = this.pageSize
      this.searchdata = data
      request('/api/supplier/material/list', 'get', data).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
</style>
